export function convertToNumber(trCurrency: string) {
  return Number(trCurrency.replace(".", "").replace(",", "."));
}

export function clearHtmlTags(html: string) {
  return html.replace(/<\/?[^>]+>/ig, "");
}

export default {
  convertToNumber,
  clearHtmlTags
}
